import mainApi from "../../api/main-api";

export default {
    getCollection({commit}, params) {
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        mainApi.getCollection(params, token).then(response => {
            commit('Collection/SAVE_COLLECTION', response, { root: true })
        });
    },
    getCollections({commit}, params) {
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        mainApi.getCollections(params, token).then(response => {
            commit('Collection/SAVE_COLLECTIONS', response, { root: true })
        });
    },
    saveCollection({commit, state}, params) {
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        mainApi.saveCollection(params, token).then(response => {
            state.messageSaveCollection = response.message
            commit('Collection/SAVE_COLLECTION_NAME', response, { root: true })
        });
    },
    resaveCollection({commit, state}, params) {
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        mainApi.resaveCollection(params, token).then(response => {
            state.messageSaveCollection = response.message
            commit('Collection/SAVE_COLLECTION_NAME', response, { root: true })
        });
    },
    sendToOrder({commit}, params) {
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        mainApi.sendToOrder(params, token).then(response => {
            commit('Collection/SAVE_COLLECTION', response, { root: true })
        });
    },
    createSpecification({rootState }, params) {
        let token = rootState.Cabinet.user.token_smart_catalog
        mainApi.createSpecification(params, token).then(response => {
            if (!response.error) {
                const url = window.URL.createObjectURL(new Blob([response])); // создаем URL для файла
                const link = document.createElement('a'); // создаем элемент <a>
                link.href = url; // устанавливаем ссылку на URL
                link.setAttribute('download', params.collection_name+'.xlsx'); // устанавливаем имя файла для скачивания
                document.body.appendChild(link); // добавляем элемент <a> в тело документа
                link.click(); // программно кликаем по ссылке для скачивания файла
                window.URL.revokeObjectURL(url); // освобождаем URL объект
            }else {
                rootState.Cabinet.showModalSaveCollection = false
                rootState.Cabinet.showModalAuthenticationSmartCatalog = true
            }
        });
    },
}
