export const CONSTANTS = {
  paletteItems: [
    {
      paletteImg: 'url('+require('../assets/img/new_back/designer_white.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/designer_white_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/designer_white_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/designer_white_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/designer_white_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/designer_white_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/designer_white_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/designer_white_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/designer_white_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/designer_white_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/designer_white_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/designer_white_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/designer_white_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/designer_white_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/designer_white_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/designer_white_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/designer_white_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/designer_white_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/designer_white_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'black',
      dataArticul: "DW",
      value: "Designer White"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/verdant.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/verdant_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/verdant_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/verdant_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/verdant_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/verdant_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/verdant_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/verdant_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/verdant_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/verdant_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/verdant_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/verdant_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/verdant_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/verdant_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/verdant_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/verdant_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/verdant_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/verdant_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/verdant_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'black',
      dataArticul: "VE",
      value: "Verdant Green"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/carbon_concrete.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/carbon_concrete_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/carbon_concrete_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/carbon_concrete_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/carbon_concrete_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/carbon_concrete_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/carbon_concrete_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/carbon_concrete_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/carbon_concrete_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/carbon_concrete_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/carbon_concrete_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/carbon_concrete_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/carbon_concrete_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/carbon_concrete_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/carbon_concrete_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/carbon_concrete_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/carbon_concrete_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/carbon_concrete_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/carbon_concrete_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'white',
      dataArticul: "CC",
      value: "Carbon Concrete"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/deep_titanium.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/deep_titanium_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/deep_titanium_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/deep_titanium_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/deep_titanium_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/deep_titanium_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/deep_titanium_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/deep_titanium_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/deep_titanium_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/deep_titanium_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/deep_titanium_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/deep_titanium_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/deep_titanium_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/deep_titanium_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/deep_titanium_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/deep_titanium_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/deep_titanium_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/deep_titanium_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/deep_titanium_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'white',
      dataArticul: "DT",
      value: "Deep Titanium"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/white_onyx.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/white_onyx_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/white_onyx_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/white_onyx_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/white_onyx_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/white_onyx_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/white_onyx_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/white_onyx_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/white_onyx_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/white_onyx_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/white_onyx_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/white_onyx_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/white_onyx_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/white_onyx_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/white_onyx_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/white_onyx_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/white_onyx_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/white_onyx_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/white_onyx_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'black',
      dataArticul: "WO",
      value: "White Onyx (Luminous)"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/gray_onyx.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/gray_onyx_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/gray_onyx_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/gray_onyx_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/gray_onyx_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/gray_onyx_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/gray_onyx_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/gray_onyx_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/gray_onyx_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/gray_onyx_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/gray_onyx_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/gray_onyx_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/gray_onyx_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/gray_onyx_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/gray_onyx_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/gray_onyx_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/gray_onyx_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/gray_onyx_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/gray_onyx_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'black',
      dataArticul: "GO",
      value: "Gray Onyx (Luminous)"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/carbon_aggregate.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/carbon_aggregate_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/carbon_aggregate_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/carbon_aggregate_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/carbon_aggregate_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/carbon_aggregate_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/carbon_aggregate_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/carbon_aggregate_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/carbon_aggregate_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/carbon_aggregate_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/carbon_aggregate_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/carbon_aggregate_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/carbon_aggregate_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/carbon_aggregate_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/carbon_aggregate_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/carbon_aggregate_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/carbon_aggregate_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/carbon_aggregate_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/carbon_aggregate_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'white',
      dataArticul: "CA",
      value: "Carbon Aggregate"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/deep_espresso.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/deep_espresso_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/deep_espresso_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/deep_espresso_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/deep_espresso_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/deep_espresso_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/deep_espresso_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/deep_espresso_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/deep_espresso_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/deep_espresso_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/deep_espresso_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/deep_espresso_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/deep_espresso_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/deep_espresso_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/deep_espresso_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/deep_espresso_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/deep_espresso_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/deep_espresso_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/deep_espresso_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'white',
      dataArticul: "DE",
      value: "Deep Espresso"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/rain_cloud.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/rain_cloud_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/rain_cloud_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/rain_cloud_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/rain_cloud_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/rain_cloud_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/rain_cloud_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/rain_cloud_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/rain_cloud_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/rain_cloud_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/rain_cloud_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/rain_cloud_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/rain_cloud_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/rain_cloud_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/rain_cloud_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/rain_cloud_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/rain_cloud_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/rain_cloud_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/rain_cloud_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'black',
      dataArticul: "RC",
      value: "Rain Cloud"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/athena_gray.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/athena_gray_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/athena_gray_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/athena_gray_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/athena_gray_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/athena_gray_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/athena_gray_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/athena_gray_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/athena_gray_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/athena_gray_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/athena_gray_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/athena_gray_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/athena_gray_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/athena_gray_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/athena_gray_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/athena_gray_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/athena_gray_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/athena_gray_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/athena_gray_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'white',
      dataArticul: "AG",
      value: "Athena Gray"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/evening_prima.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/evening_prima_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/evening_prima_classic_pdf.png')+')',

      classicImgPdf6: 'url('+require('../assets/img/classic/evening_prima_classic_6.png')+')',
      classicImg6: 'url('+require('../assets/img/classic/evening_prima_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/evening_prima_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/evening_prima_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/evening_prima_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/evening_prima_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/evening_prima_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/evening_prima_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/evening_prima_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/evening_prima_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/evening_prima_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/evening_prima_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/evening_prima_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/evening_prima_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/evening_prima_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/evening_prima_classic_horisontal_pdf_16.png')+')',
      dataIconColor: 'white',
      dataArticul: "EP",
      value: "Evening Prima"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/deep_nocturne.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/deep_nocturne_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/deep_nocturne_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/deep_nocturne_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/deep_nocturne_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/deep_nocturne_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/deep_nocturne_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/deep_nocturne_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/deep_nocturne_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/deep_nocturne_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/deep_nocturne_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/deep_nocturne_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/deep_nocturne_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/deep_nocturne_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/deep_nocturne_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/deep_nocturne_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/deep_nocturne_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/deep_nocturne_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/deep_nocturne_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'white',
      dataArticul: "DN",
      value: "Deep Nocturne"
    },
    {
      paletteImg: 'url('+require('../assets/img/new_back/ash-nature.jpg')+')',

      classicImg: 'url('+require('../assets/img/classic/ash_nature_classic.png')+')',
      classicImgPdf: 'url('+require('../assets/img/classic/ash_nature_classic_pdf.png')+')',

      classicImg6: 'url('+require('../assets/img/classic/ash_nature_classic_6.png')+')',
      classicImgPdf6: 'url('+require('../assets/img/classic/ash_nature_classic_pdf_6.png')+')',
      classicImgH6: 'url('+require('../assets/img/classic/ash_nature_classic_horisontal_6.png')+')',
      classicImgPdfH6: 'url('+require('../assets/img/classic/ash_nature_classic_horisontal_pdf_6.png')+')',

      classicImg8: 'url('+require('../assets/img/classic/ash_nature_classic_8.png')+')',
      classicImgPdf8: 'url('+require('../assets/img/classic/ash_nature_classic_pdf_8.png')+')',
      classicImgH8: 'url('+require('../assets/img/classic/ash_nature_classic_horisontal_8.png')+')',
      classicImgHPdf8: 'url('+require('../assets/img/classic/ash_nature_classic_horisontal_pdf_8.png')+')',

      classicImg12: 'url('+require('../assets/img/classic/ash_nature_classic_12.png')+')',
      classicImgPdf12: 'url('+require('../assets/img/classic/ash_nature_classic_pdf_12.png')+')',
      classicImgH12: 'url('+require('../assets/img/classic/ash_nature_classic_horisontal_12.png')+')',
      classicImgHPdf12: 'url('+require('../assets/img/classic/ash_nature_classic_horisontal_pdf_12.png')+')',

      classicImg16: 'url('+require('../assets/img/classic/ash_nature_classic_16.png')+')',
      classicImgPdf16: 'url('+require('../assets/img/classic/ash_nature_classic_pdf_16.png')+')',
      classicImgH16: 'url('+require('../assets/img/classic/ash_nature_classic_horisontal_16.png')+')',
      classicImgHPdf16: 'url('+require('../assets/img/classic/ash_nature_classic_horisontal_pdf_16.png')+')',

      dataIconColor: 'white',
      dataArticul: "AN",
      value: "Ash Nature"
    },
  ],
  woodItems: [
    {
      paletteImg: 'url('+require('../assets/img/wood/bog_oak.jpeg')+')',
      paletteImgH: 'url('+require('../assets/img/wood/bog_oak-horisontal.jpeg')+')',
      dataIconColor: 'white',
      dataArticul: "BO",
      value: "BOG OAK",
      type: 'A',
    },
    // {
    //   paletteImg: 'url('+require('../assets/img/wood/bubinga.jpeg')+')',
    //   paletteImgH: 'url('+require('../assets/img/wood/bubinga-horisontal.jpeg')+')',
    //   dataIconColor: 'white',
    //   dataArticul: "BB",
    //   value: "BUBINGA",
    //   type: 'B',
    // },
    {
      paletteImg: 'url('+require('../assets/img/wood/amarant.jpg')+')',
      paletteImgH: 'url('+require('../assets/img/wood/amarant-horisontal.jpg')+')',
      dataIconColor: 'white',
      dataArticul: "AM",
      value: "AMARANT",
      type: 'B',
    },
    {
      paletteImg: 'url('+require('../assets/img/wood/ebony.jpeg')+')',
      paletteImgH: 'url('+require('../assets/img/wood/ebony-horisontal.jpeg')+')',
      dataIconColor: 'white',
      dataArticul: "EB",
      value: "EBONY",
      type: 'A',
    },
    {
      paletteImg: 'url('+require('../assets/img/wood/palisandr.jpeg')+')',
      paletteImgH: 'url('+require('../assets/img/wood/palisandr-horisontal.jpeg')+')',
      dataIconColor: 'white',
      dataArticul: "PL",
      value: "PALISANDR",
      type: 'B',
    },
    {
      paletteImg: 'url('+require('../assets/img/wood/venge.jpeg')+')',
      paletteImgH: 'url('+require('../assets/img/wood/venge-horisontal.jpeg')+')',
      dataIconColor: 'white',
      dataArticul: "VN",
      value: "VENGE",
      type: 'B',
    },
    {
      paletteImg: 'url('+require('../assets/img/wood/walnut.jpeg')+')',
      paletteImgH: 'url('+require('../assets/img/wood/walnut-horisontal.jpeg')+')',
      dataIconColor: 'white',
      dataArticul: "WL",
      value: "WALNUT",
      type: 'B',
    },
    {
      paletteImg: 'url('+require('../assets/img/wood/zebrano.jpeg')+')',
      paletteImgH: 'url('+require('../assets/img/wood/zebrano-horisontal.jpeg')+')',
      dataIconColor: 'white',
      dataArticul: "ZB",
      value: "ZEBRANO",
      type: 'B',
    },
    {
      paletteImg: 'url('+require('../assets/img/wood/zircote.jpeg')+')',
      paletteImgH: 'url('+require('../assets/img/wood/zircote-horisontal.jpeg')+')',
      dataIconColor: 'white',
      dataArticul: "ZR",
      value: "ZIRICOTE",
      type: 'A',
    },
  ],
  namesColorSockets: {
    SL: "aluminum",
    AM: "anthracitMat",
    WG: 'WhiteGloss',
    BG: 'blackGloss',
    CH: 'Champagne',
    WM: 'WhiteMat',
    BM: 'blackMat',
    MO: 'Mokko',
    NF: 'notFound'
  }
}

export function getСipherIcons (count, icons) {
  let iconArr = icons.slice(0, count);
  return iconArr.join('&')
}
