<template>

  <div class="pop-up authentication" :class="{'opened': isVisible}">
    <div class="pop-up-content">
      <div class="pop-up-close" @click="closeModal">&#10006;</div>
      <img style="margin: 0 auto; padding-top: 20px; display: block" src="../../assets/img/logo-smart-catalog-black.png" alt="" >
      <h2 style="text-align: center">Linking accounts with Smart Catalog</h2>
      <div>
        <form>
          <label class="text-primary">
            <p>Email</p>
            <input type="text" v-model="smart_catalog_email">
            <p
                    v-if="messageAuthenticationSmartCatalog.authorization_email && messageAuthenticationSmartCatalog.authorization_email.message"
                    v-html="messageAuthenticationSmartCatalog.authorization_email.message"
                    style="color: red; position: absolute"
            />
          </label>
          <label class="text-primary">
            <p>Password</p>
            <input type="password" v-model="smart_catalog_password">
            <p
                    v-if="messageAuthenticationSmartCatalog.authorization_password && messageAuthenticationSmartCatalog.authorization_password.message"
                    v-html="messageAuthenticationSmartCatalog.authorization_password.message"
                    style="color: red; position: absolute"
            />
          </label>

          <button @click="linkingSmartCatalog" class="addToCollection__button">Linking</button>
          <a style="margin-top: 15px" href="https://smarttech.com.ua/catalog/" target="_blank">Don't have an account in Smart Catalog? Register here</a>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from "vuex";
  import _debounce from 'lodash/debounce'

  export default {
    name: "AuthenticationSmartCatalog",
    props: {
      isVisible: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        smart_catalog_email: '',
        smart_catalog_password: '',
      }
    },
    computed: {
      ...mapGetters({
        messageAuthenticationSmartCatalog: "Cabinet/messageAuthenticationSmartCatalog",
      })
    },
    methods: {
      ...mapActions({
        LINKING_SMART_CATALOG: "Cabinet/LINKING_SMART_CATALOG",
      }),
      ...mapMutations({
        // LINKING_SMART_CATALOG: "Cabinet/LINKING_SMART_CATALOG",
      }),
      userAuthorizationDelay: _debounce(function (params) {
        this.USER_AUTHORIZATION(params)
      }, 200),
      linkingSmartCatalog(event) {
        event.preventDefault()
        const params = {
          lang: 'en',
          authorization_email: this.smart_catalog_email,
          authorization_password: this.smart_catalog_password,
        }
        this.LINKING_SMART_CATALOG(params)
      },
      closeModal() {
        this.$emit('close')
      },

    }
  }
</script>

<style lang="scss">
  .select-icon.opened {
    display: flex;
  }
</style>
