<template lang="pug">
  .ComboSlot(v-if="rerender")
    span.ComboSlot__Refresh(@click="$emit('open-select-menu')" v-if="mode === 'combo' || mode === 'sockets'")
      i.el-icon-s-tools
    div.ComboSlot_R1(v-if="slotValue === 'R1'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageSocket()")
    div.ComboSlot_R1(v-if="slotValue === 'FB1'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageFranko()")
    div.ComboSlot_R1(v-if="slotValue === 'U1'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageBritish()")
    div.ComboSlot_R1(v-if="slotValue === 'I1'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageIndian()")
    div.ComboSlot_R1(v-if="slotValue === 'ETH1'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageEthernet()")
    div.ComboSlot_R1(v-if="slotValue === 'ETH2'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageEthernet2()")
    div.ComboSlot_R1(v-if="slotValue === 'HD1'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageHD()")
    div.ComboSlot_R1(v-if="slotValue === 'AS'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageAS()")
    div.ComboSlot_R1(v-if="slotValue === 'TV1'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageTV()")
    div.ComboSlot_R1(v-if="slotValue === 'TV2'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageTV2()")
    div.ComboSlot_Switch(v-if="slotValue === 'B4'")
      ComboSlotTouch(:count-touch="4" @icon-click="goToSelectIcon" :current-slot="slotIndex")
    div.ComboSlot_Switch(v-if="slotValue === 'B6'")
      ComboSlotTouch(:count-touch="6" @icon-click="goToSelectIcon" :current-slot="slotIndex")
    //div.ComboSlot_R1(v-if="slotValue === 'A4'")
      img(:src="getURLImageA4()")
    //div.ComboSlot_R1(v-if="slotValue === 'A6'")
      img(:src="getURLImageA6()")

    div.ComboSlot_RCH2ac(v-if="slotValue === 'RCHac'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageSocketUSB()")
    div.ComboSlot_CH2ac(v-if="slotValue === 'CHac'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      img(:src="getURLImageUSB()")
    div.ComboSlot_Socket(v-if="slotValue === 'F1'")
      div(:style="{backgroundImage: getURLImageWood()}" :class="'socket_wood'")
      div.shadow
        img(:src="getURLImageSK()")
    div.ComboSlot_Switch(v-if="slotValue === '01'")
      ComboSlotTouch(:count-touch="1" @icon-click="goToSelectIcon" :current-slot="slotIndex")
    div.ComboSlot_Switch(v-if="slotValue === 'H02'")
      ComboSlotTouch(:count-touch="2" orientation="Horizontal" @icon-click="goToSelectIcon" :current-slot="slotIndex")
    div.ComboSlot_Switch(v-if="slotValue === 'V02'")
      ComboSlotTouch(:count-touch="2" orientation="Vertical" @icon-click="goToSelectIcon" :current-slot="slotIndex")
    div.ComboSlot_Switch(v-if="slotValue === '04'")
      ComboSlotTouch(:count-touch="4" @icon-click="goToSelectIcon" :current-slot="slotIndex")
    div.ComboSlot_Switch(v-if="slotValue === 'H08' || slotValue === 'V08'")
      ComboSlotTouch(:count-touch="8" :orientation="comboParameters.orientation" @icon-click="goToSelectIcon" :current-slot="slotIndex")
    ModalSelectIcon(@select-icon="selectIcon" @close="closeModalSelectIcon" :isVisible="showModalSelectIcon" :currentTouch="currentTouch" destination="combo")

</template>

<script>
import {mapGetters} from "vuex";
import {CONSTANTS} from "@/utilites/utilites"
import ComboSlotTouch from "./ComboSlotTouch";
import ModalSelectIcon from "../Modals/SelectIcon";

export default {
  name: "ComboSlot",
  components: {ModalSelectIcon, ComboSlotTouch},
  data(){
    return {
      namesColorSockets: CONSTANTS['namesColorSockets'],
      currentTouch: -1,
      showModalSelectIcon: false,
      rerender: true
    }
  },
  computed: {
    ...mapGetters({
      comboParameters: 'Parameters/comboParameters',
      selectedComboWood: 'Parameters/selectedComboWood',
    }),
    currentSocketName() {
      return this.namesColorSockets[this.comboParameters.colorSocket]
    }
  },
  props: {
    slotValue: {
      type: String,
      default: 'NF'
    },
    slotIndex: {
      type: Number,
      default: -1
    },
    mode: {
      type: String,
      default: ""
    }
  },
  methods: {
    getURLImageSocket(){
      return require(`../../assets/img/sockets/${this.currentSocketName}-round.png`)
    },
    getURLImageSocketUSB(){
      return require(`../../assets/img/sockets/${this.currentSocketName}-socket-usb.png`)
    },
    getURLImageUSB(){
      // return require(`../../assets/img/sockets/${this.currentSocketName !== "notFound" ? 'color' : this.currentSocketName}-usb-equal.png`)
      return require(`../../assets/img/sockets/color-usb-equal.png`)
    },
    getURLImageSK(){
      return require(`../../assets/img/sockets/socket.png`)
    },
    getURLImageFranko(){
      return require(`../../assets/img/sockets/${this.currentSocketName}-franko.png`)
    },
    getURLImageBritish(){
      return require(`../../assets/img/sockets/background-british.png`)
      // if (this.currentSocketName === 'notFound'){
      //   return require(`../../assets/img/sockets/notFound-british.png`)
      // }else {
      //   return require(`../../assets/img/sockets/background-british.png`)
      // }
    },
    getURLImageIndian(){
      return require(`../../assets/img/sockets/background-indian.png`)
      // if (this.currentSocketName === 'notFound'){
      //   return require(`../../assets/img/sockets/notFound-indian.png`)
      // }else {
      //   return require(`../../assets/img/sockets/background-indian.png`)
      // }
    },
    getURLImageEthernet(){
      return require(`../../assets/img/sockets/background-eth.png`)
      // if (this.currentSocketName === 'notFound'){
      //   return require(`../../assets/img/sockets/notFound-eth.png`)
      // }else {
      //   return require(`../../assets/img/sockets/background-eth.png`)
      // }
    },
    getURLImageEthernet2(){
      return require(`../../assets/img/sockets/background-eth2.png`)
      // if (this.currentSocketName === 'notFound'){
      //   return require(`../../assets/img/sockets/notFound-eth2.png`)
      // }else {
      //   return require(`../../assets/img/sockets/background-eth2.png`)
      // }
    },
    getURLImageHD(){
      return require(`../../assets/img/sockets/background-hdmi.png`)
      // if (this.currentSocketName === 'notFound'){
      //   return require(`../../assets/img/sockets/notFound-hdmi.png`)
      // }else {
      //   return require(`../../assets/img/sockets/background-hdmi.png`)
      // }
    },
    getURLImageAS(){
      return require(`../../assets/img/sockets/background-as.png`)
      // if (this.currentSocketName === 'notFound'){
      //   return require(`../../assets/img/sockets/notFound-as.png`)
      // }else {
      //   return require(`../../assets/img/sockets/background-as.png`)
      // }
    },
    getURLImageTV(){
      return require(`../../assets/img/sockets/background-tv.png`)
      // if (this.currentSocketName === 'notFound'){
      //   return require(`../../assets/img/sockets/notFound-tv.png`)
      // }else {
      //   return require(`../../assets/img/sockets/background-tv.png`)
      // }
    },
    getURLImageTV2(){
      return require(`../../assets/img/sockets/background-tv2.png`)
      // if (this.currentSocketName === 'notFound'){
      //   return require(`../../assets/img/sockets/notFound-tv2.png`)
      // }else {
      //   return require(`../../assets/img/sockets/background-tv2.png`)
      // }
    },
    // getURLImageA4(){
    //   if (this.currentSocketName === 'notFound'){
    //     return require(`../../assets/img/sockets/notFound-a4.png`)
    //   }else {
    //     return require(`../../assets/img/sockets/background-tv2.png`)
    //   }
    // },
    getURLImageWood(){
      return this.selectedComboWood.paletteImg
    },

    goToSelectIcon(i){
      this.currentTouch = i;
      this.showModalSelectIcon = true;
    },
    selectIcon(icon){
      this.comboParameters.slotsTouchIcons[this.slotIndex][+this.currentTouch - 1] = icon.iconName;
      this.showModalSelectIcon = false
      this.rerender = false;
      setTimeout(() => {
        this.rerender = true;
      },0)
    },
    closeModalSelectIcon(){
      this.showModalSelectIcon = false
    },
  }
}
</script>

<style lang="scss">
.ComboSlot {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &_R1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      border-radius: 50%;
      overflow: hidden;
      width: 150px;

    }
    //padding-top: 100%;
  }
  &_RCH2ac {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      width: 150px;

    }
    //padding-top: 100%;
  }
  &_CH2ac {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      width: 150px;

    }
    //padding-top: 100%;
  }
  &_Socket {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      width: 100px;
      position: relative;
      z-index: 1;
    }
    .shadow {
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 6%;
        right: 6%;
        bottom: 6%;
        left: 6%;
        box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.75);
        border-radius: 14%;
        background-color: #333;
      }
    }
  }
  &_Switch{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    .ComboSlot__Refresh {
      opacity: 1;
    }
  }
  &__Refresh {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.25s;
    z-index: 99;
    i {
      display: block;
      font-size: 20px;
      color: #fff;
    }
  }
}
</style>
