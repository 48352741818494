import mainApi from '../../api/main-api'
import router from '../../router';

export default {
    USER_AUTHORIZATION(state, params) {
        userAuthorization(state, params)
    },
    USER_REGISTRATION(state, params) {
        mainApi.userRegistration(params).then(response => {
            if (!response.error) {
                userAuthorization(state, params);
            } else {
                state.messageRegistration = response.error
            }
        });
    },
    // LINKING_SMART_CATALOG(state, params){
    //     mainApi.linkingSmartCatalog(params).then(response => {
    //         if (!response.error) {
    //             let params = response
    //             let token = state.user.token
    //             mainApi.saveTokenSmartCatalog(params, token).then(response_2 => {
    //                 // localStorage.setItem('token_smart_catalog', JSON.stringify(response_2));
    //                 state.user.token_smart_catalog = response_2
    //             });
    //         } else {
    //             state.messageAuthenticationSmartCatalog = response.error
    //         }
    //     });
    // },
    MODAL_AUTHENTICATION(state, payload) {
        state.showModalAuthentication = payload
    },
    MODAL_AUTHENTICATION_SMART_CATALOG(state, payload) {
        state.showModalAuthenticationSmartCatalog = payload
    },
    MODAL_SAVE_COLLECTION(state, payload) {
        state.showModalSaveCollection = payload
    },
    USER_LOGOUT(state) {
        router.push("/")
        state.user = {
            id: '',
            email: '',
            login: '',
            token: '',
        }
        localStorage.removeItem('token');
    },
    GET_USER(state) {
        get_user(state)
        // let token = state.user.token
        // mainApi.getUser(token).then(response => {
        //     state.user.id = response.ID
        //     state.user.email = response.user_email
        //     state.user.login = response.user_nicename
        //     state.user.token_smart_catalog = response.token_smart_catalog
        // });
    },
}
function get_user(state) {
    let token = state.user.token
    mainApi.getUser(token).then(response => {
        state.user.id = response.ID
        state.user.email = response.user_email
        state.user.login = response.user_nicename
        state.user.token_smart_catalog = response.token_smart_catalog
    });
}
function userAuthorization(state, params) {
    mainApi.userAuthorization(params).then(response => {
        if (!response.error) {
            state.user.id = response.user_id
            state.user.email = response.user_email
            state.user.login = response.user_nicename
            state.user.token = response.token

            get_user(state)

            localStorage.setItem('token', JSON.stringify(response.token));
            state.showModalAuthentication = false
            state.messageAuthorization = ''
        } else {
            state.messageAuthorization = response.error
        }
    });
}