<template>

  <div class="pop-up authentication" :class="{'opened': isVisible}">
    <div class="pop-up-content">
      <div class="pop-up-close" @click="closeModal">&#10006;</div>
      <ul class="tab-pane">
        <li :class="{'active': tab === 'Authorization'}" @click="changeTab('Authorization')">Authorization</li>
        <li :class="{'active': tab === 'Registration'}" @click="changeTab('Registration')">Registration</li>
      </ul>
      <div v-show="tab === 'Authorization'">
        <form>
          <label class="text-primary">
            <p>Email / Login</p>
            <input type="text" v-model="username">
          </label>
          <label class="text-primary">
            <p>Password</p>
            <input type="password" v-model="password">
          </label>
          <button @click="authorization" class="addToCollection__button">Login</button>
          <p v-html="messageAuthorization"/>
          <a style="margin-top: 15px" href="https://ns-touch.com/wp-login.php?action=lostpassword">Forgot password</a>
        </form>
      </div>
      <div v-show="tab === 'Registration'">
        <form>
          <label class="text-primary">
            <p>Name</p>
            <input type="text" name="reg_username" v-model="reg_username">
          </label>
          <label class="text-primary">
            <p>Email</p>
            <input type="text" name="reg_email" v-model="reg_email">
          </label>
          <label class="text-primary">
            <p>Password</p>
            <input type="password" name="reg_password" v-model="reg_password">
          </label>
          <button @click="registration" class="addToCollection__button">Register</button>
          <p v-html="messageRegistration"/>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapMutations} from "vuex";
  import _debounce from 'lodash/debounce'

  export default {
    name: "ModalAuthentication",
    props: {
      isVisible: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        tab: 'Authorization',
        username: '',
        email: '',
        password: '',
        reg_username: '',
        reg_email: '',
        reg_password: '',
      }
    },
    computed: {
      ...mapGetters({
        messageAuthorization: "Cabinet/messageAuthorization",
        messageRegistration: "Cabinet/messageRegistration",
      })
    },
    methods: {
      ...mapMutations({
        USER_AUTHORIZATION: "Cabinet/USER_AUTHORIZATION",
        USER_REGISTRATION: "Cabinet/USER_REGISTRATION",
      }),
      userAuthorizationDelay: _debounce(function (params) {
        this.USER_AUTHORIZATION(params)
      }, 200),
      registration(event) {
        event.preventDefault()
        const params = {
          username: this.reg_username,
          email: this.reg_email,
          password: this.reg_password,
        }
        this.USER_REGISTRATION(params)
        // this.userAuthorizationDelay(params)
      },
      authorization(event) {
        event.preventDefault()
        const params = {
          username: this.username,
          password: this.password,
        }
        this.USER_AUTHORIZATION(params)
      },
      changeTab(tabName) {
        this.tab = tabName
      },
      closeModal() {
        this.$emit('close')
      },

    }
  }
</script>

<style lang="scss">
  .select-icon.opened {
    display: flex;
  }
</style>
