export default () => ({
    user: {
        id: '',
        email: '',
        login: '',
        token: localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : [],
        // token_smart_catalog: localStorage.getItem('token_smart_catalog') && localStorage.getItem('token_smart_catalog') !== 'undefined' ? JSON.parse(localStorage.getItem('token_smart_catalog')) : [],
        token_smart_catalog: '',
    },
    showModalAuthentication: false,
    showModalAuthenticationSmartCatalog: false,
    showModalSaveCollection: false,
    messageAuthorization: '',
    messageRegistration: '',
    messageAuthenticationSmartCatalog: '',
})