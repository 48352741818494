<template lang="pug">
  .ComboSmallPreview(
    @click="$emit('select-combo', artikul)"
    :data-orientation="orientation.toLowerCase()"
    :style="{backgroundImage: paletteTouch}"
    :data-series="series"
    :data-artikul="artikul"
  )
    //p {{notEmptySlots}}
    //p {{slots}}
    .ComboSmallPreview__Item(
      v-for="(slot, index) in notEmptySlots"
      :data-inlay="inlay"
      :key="slot + index"
      :style="{...getStylesSize(slot)}"
    )
      //p {{slot}}
      .ComboSmallPreview__Item-Slot(v-if="colorSocket.length > 0 && slot === 'R1'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageRound()")
      .ComboSmallPreview__Item-Slot(v-else-if="colorSocket.length > 0 && slot === 'FB1'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageFranko()")
      .ComboSmallPreview__Item-Slot(v-else-if="colorSocket.length > 0 && slot === 'U1'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageBritish()")
      .ComboSmallPreview__Item-Slot(v-else-if="colorSocket.length > 0 && slot === 'I1'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageIndian()")
      .ComboSmallPreview__Item-Slot(v-else-if="colorSocket.length > 0 && slot === 'ETH1'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageEthernet()")
      .ComboSmallPreview__Item-Slot(v-else-if="colorSocket.length > 0 && slot === 'ETH2'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageEthernet2()")
      .ComboSmallPreview__Item-Slot(v-else-if="colorSocket.length > 0 && slot === 'HD1'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageHD()")
      .ComboSmallPreview__Item-Slot(v-else-if="colorSocket.length > 0 && slot === 'AS'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageAS()")
      .ComboSmallPreview__Item-Slot(v-else-if="colorSocket.length > 0 && slot === 'TV1'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageTV()")
      .ComboSmallPreview__Item-Slot(v-else-if="colorSocket.length > 0 && slot === 'TV2'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageTV2()")
      .ComboSmallPreview__Item-Slot(v-else-if="colorSocket.length > 0 && slot === 'RCHac'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageSocketUSB()")
      .ComboSmallPreview__Item-Slot(v-else-if="colorSocket.length > 0 && slot === 'CHac'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        img(:src="getURLImageUSB()")
      .ComboSmallPreview__Item-Slot.ComboSmallPreview__Item-S1(v-else-if="slot === 'F1'")
        div(:style="{backgroundImage: woodCombo()}" :class="'socket_wood'")
        div.shadow(:class="{'with-sh': palette.length > 0}")
          img(:src="getURLImageSK()")

      //v-if="slotsIcons[index] && getIconUrl(0, index )"
      .ComboSmallPreview__Item-Slot.ComboSmallPreview__Item-01(v-else-if="slot === 'K1' || slot === '01' || slot === '11' || slot === '21'")
        div.icon(
          :style="{backgroundImage: woodCombo()}"
        )
          img(:src="getIconUrl(0, index)")

      //v-if="slotsIcons[index] && getIconUrl(i - 1, index )"
      .ComboSmallPreview__Item-Slot.ComboSmallPreview__Item-V02(v-else-if="slot === 'VK2' || slot === 'V02' || slot === 'V12' || slot === 'V22'")
        div.icon(
          v-for="i in 2"
          :style="{backgroundImage: woodCombo()}"
        )
          img(:src="getIconUrl(i - 1, index)")

      //v-if="slotsIcons[index] && getIconUrl(i - 1, index )"
      .ComboSmallPreview__Item-Slot.ComboSmallPreview__Item-H02(v-else-if="slot === 'HK2' || slot === 'H02' || slot === 'H12' || slot === 'H22'")
        div.icon(
          v-for="i in 2"
          :style="{backgroundImage: woodCombo()}"
        )
          img(:src="getIconUrl(i - 1, index)")

      // v-if="slotsIcons[index] && getIconUrl(i - 1, index )"
      .ComboSmallPreview__Item-Slot.ComboSmallPreview__Item-04(v-else-if="(slot === 'K4' && !isActiveSeries()) || slot === '04' || slot === '14' || slot === '24'")
        div.icon(
          :class="'n5 icon-num-' + i"
          v-for="i in 4"
          :style="{backgroundImage: woodCombo()}"
        )
          img(:src="getIconUrl(i - 1, index)")

      //v-if="slotsIcons[index] && getIconUrl(i - 1, index )"
      .ComboSmallPreview__Item-Slot.ComboSmallPreview__Item-H08(v-else-if="slot === 'HK8' || slot === 'H08' || slot === 'H18' || slot === 'H28'")
        .help
        div.icon(
          :class="'n5 icon-num-' + i"
          v-for="i in 8"
          :style="{backgroundImage: woodCombo()}"
        )
          img(:src="getIconUrl(i - 1, index)")
        .help

      .ComboSmallPreview__Item-Slot.ComboSmallPreview__Item-B4(v-else-if="slot === 'B4' || slot === 'K4'")
        div.icon( :class="'n5 icon-num-' + i" v-for="i in 4" v-if="slotsIcons[index]")
          img(v-if="getIconUrl(i - 1, index)" :src="getIconUrl(i - 1, index)")

      .ComboSmallPreview__Item-Slot.ComboSmallPreview__Item-B6(v-else-if="slot === 'B6' || slot === 'K6'")
        div.icon( :class="'n5 icon-num-' + i" v-for="i in 6" v-if="slotsIcons[index]")
          img(v-if="getIconUrl(i - 1, index )" :src="getIconUrl(i - 1, index)")




      .ComboSmallPreview__Item-Slot(:class="'ComboSmallPreview__Item-' + slot" v-else)
</template>

<script>
  import {CONSTANTS} from "@/utilites/utilites"

  export default {
    name: "ComboSmallPreview",
    props: {
      artikul: {
        type: String,
        default: ''
      },
      palette: {
        type: String,
        default: ''
      },
      sizeSlot: {
        type: Number,
        default: 50
      },
      colorSocket: {
        type: String,
        default: ''
      },
      icons: {
        type: Array,
        default: () => []
      },
      parameters: {
        type: Object,
      },
    },
    data() {
      return {
        paletteItems: CONSTANTS['paletteItems'],
        woodItems: CONSTANTS['woodItems'],
        namesColorSockets: CONSTANTS['namesColorSockets'],
        spaceSlots: 0,
        slots: {
          1: null,
          2: null,
          3: null,
          4: null,
          5: null
        }
      }
    },
    computed: {
      trimArtikul() {
        return this.artikul.replace(/\s/g, '');
      },
      arrayArtikul() {
        return this.trimArtikul.split('-')
      },
      slotsCount() {
        let code = this.arrayArtikul[0]
        if (code.substr(0, 5).toLowerCase() === 'combo') {
          return +code.charAt(5)
        }
        return 1
      },
      orientation() {
        let code = this.arrayArtikul[0]
        if (code.substr(0, 5).toLowerCase() === 'combo') {
          return code.charAt(6) === "H" ? "Horizontal" : "Vertical"
        }
        return 1
      },
      series() {
        let code = this.arrayArtikul[0]
        if (code.substr(0, 5).toLowerCase() === 'combo') {
          if (code.substr(7, 3) === 'C85') {
            return 'C85'
          }
          if (code.substr(7, 3) === 'W85') {
            return 'W85'
          }
          if (code.substr(7, 3) === 'A85') {
            return 'A85'
          }
          if (code.substr(7, 3) === 'A90') {
            return 'A90'
          }
          return code.substr(8, 2)
        }
        return 1
      },
      inlay() {
        let inlay = 'AL'
        if (this.arrayArtikul[2]) inlay = this.arrayArtikul[2].substr(3, 2)
        return inlay
      },
      notEmptySlots() {
        let arr = []
        Object.keys(this.slots).forEach(slot => {
          if (this.slots[slot]) arr.push(this.slots[slot])
        })
        return arr
      },
      paletteTouch() {
        let url = ''
        this.paletteItems.forEach(palette => {
          if (palette.value === this.palette) {
            if (this.series === "C85") {
              if (this.orientation === "Horizontal") {
                if (this.slotsCount == 2) {
                  url = palette.classicImgHPdf8
                } else if (this.slotsCount == 3) {
                  url = palette.classicImgHPdf12
                } else if (this.slotsCount == 4) {
                  url = palette.classicImgHPdf16
                } else if (this.slotsCount == 5) {
                  url = palette.classicImgHPdf16
                } else {
                  url = palette.classicImg
                }
              } else {
                if (this.slotsCount == 2) {
                  url = palette.classicImgPdf8
                } else if (this.slotsCount == 3) {
                  url = palette.classicImgPdf12
                } else if (this.slotsCount == 4) {
                  url = palette.classicImgPdf16
                } else if (this.slotsCount == 5) {
                  url = palette.classicImgPdf16
                } else {
                  url = palette.classicImg
                }
              }
            } else {
              url = palette.paletteImg;
            }

          }
        })
        return url
      },
      currentSocketName() {
        return this.namesColorSockets[this.colorSocket]
      },
      slotsIcons() {
        let currentIcons = [];
        let currInd = 0;
        if (this.icons.length) {
          this.notEmptySlots.forEach((slot, i) => {
            if (slot === 'K1' || slot === '01' || slot === '11' || slot === '21') {
              currentIcons[i] = this.icons[currInd] ? this.icons[currInd++].slice(0, 1) : currInd++;
            } else if (slot === 'HK2' || slot === 'H02' || slot === 'H12' || slot === 'H22') {
              currentIcons[i] = this.icons[currInd] ? this.icons[currInd++].slice(0, 8) : currInd++;
            } else if (slot === 'VK2' || slot === 'V02' || slot === 'V12' || slot === 'V22') {
              currentIcons[i] = this.icons[currInd] ? this.icons[currInd++].slice(0, 8) : currInd++;
            } else if (slot === 'K4' || slot === '04' || slot === '14' || slot === '24' || slot === 'B4') {
              currentIcons[i] = this.icons[currInd] ? this.icons[currInd++].slice(0, 4) : currInd++;
            } else if (slot === 'B6' || slot === 'K6') {
              currentIcons[i] = this.icons[currInd] ? this.icons[currInd++].slice(0, 6) : currInd++;
            } else if (slot === 'HK8' || slot === 'H08' || slot === 'H18' || slot === 'H28') {
              currentIcons[i] = this.icons[currInd] ? this.icons[currInd++].slice(0, 8) : currInd++;
            }
          })
        }
        return currentIcons
      },
      colorIcon() {
        let find = this.paletteItems.find(item => item.value === this.palette);
        return find ? find.dataIconColor.charAt(0) : "b"
      },
    },
    mounted() {
      let remainder = this.arrayArtikul[1];
      for (let i = 1; i < this.slotsCount + 1; i++) {
        if (this.spaceSlots > 0) {
          this.spaceSlots--;
        } else {
          remainder = this.firstNameSlot(remainder, i);
        }
      }
    },
    methods: {
      firstNameSlot(code, i) {
        let size = 0;
        if (code.substr(0, 2) === '01') {
          this.setSlot("01", i);
          size = 2
        } else if (code.substr(0, 2) === '11') {
          this.setSlot("11", i);
          size = 2
        } else if (code.substr(0, 2) === '21') {
          this.setSlot("21", i);
          size = 2
        } else if (code.substr(0, 2) === '04') {
          this.setSlot("04", i);
          size = 2
        } else if (code.substr(0, 2) === 'B4') {
          this.setSlot("B4", i);
          size = 3
        } else if (code.substr(0, 2) === 'B6') {
          this.setSlot("B6", i);
          size = 3
        } else if (code.substr(0, 2) === '14') {
          this.setSlot("14", i);
          size = 2
        } else if (code.substr(0, 2) === '24') {
          this.setSlot("24", i);
          size = 2
        } else if (code.substr(0, 2) === 'R1') {
          this.setSlot("R1", i);
          size = 2
        } else if (code.substr(0, 2) === 'R2') {
          this.setSlot("R1", i);
          this.setSlot("R1", i + 1);
          this.spaceSlots = 1;
          size = 2
        } else if (code.substr(0, 2) === 'R3') {
          this.setSlot("R1", i);
          this.setSlot("R1", i + 1);
          this.setSlot("R1", i + 2);
          this.spaceSlots = 2;
          size = 2
        } else if (code.substr(0, 2) === 'R4') {
          this.setSlot("R1", i);
          this.setSlot("R1", i + 1);
          this.setSlot("R1", i + 2);
          this.setSlot("R1", i + 3);
          this.spaceSlots = 3;
          size = 2
        } else if (code.substr(0, 2) === 'R5') {
          this.setSlot("R1", i);
          this.setSlot("R1", i + 1);
          this.setSlot("R1", i + 2);
          this.setSlot("R1", i + 3);
          this.setSlot("R1", i + 4);
          this.spaceSlots = 3;
          size = 2
        } else if (code.substr(0, 2) === 'F1') {
          this.setSlot("F1", i);
          size = 2
        } else if (code.substr(0, 2) === 'F2') {
          this.setSlot("F1", i);
          this.setSlot("F1", i + 1);
          this.spaceSlots = 1;
          size = 2
        } else if (code.substr(0, 2) === 'F3') {
          this.setSlot("F1", i);
          this.setSlot("F1", i + 1);
          this.setSlot("F1", i + 2);
          this.spaceSlots = 2;
          size = 2
        } else if (code.substr(0, 2) === 'F4') {
          this.setSlot("F1", i);
          this.setSlot("F1", i + 1);
          this.setSlot("F1", i + 2);
          this.setSlot("F1", i + 3);
          this.spaceSlots = 3;
          size = 2
        } else if (code.substr(0, 2) === 'F5') {
          this.setSlot("F1", i);
          this.setSlot("F1", i + 1);
          this.setSlot("F1", i + 2);
          this.setSlot("F1", i + 3);
          this.setSlot("F1", i + 4);
          this.spaceSlots = 3;
          size = 2
        } else if (code.substr(0, 2) === 'U1') {
          this.setSlot("U1", i);
          size = 2
        } else if (code.substr(0, 2) === 'U2') {
          this.setSlot("U1", i);
          this.setSlot("U1", i + 1);
          this.spaceSlots = 1;
          size = 2
        } else if (code.substr(0, 2) === 'U3') {
          this.setSlot("U1", i);
          this.setSlot("U1", i + 1);
          this.setSlot("U1", i + 2);
          this.spaceSlots = 2;
          size = 2
        } else if (code.substr(0, 2) === 'U4') {
          this.setSlot("U1", i);
          this.setSlot("U1", i + 1);
          this.setSlot("U1", i + 2);
          this.setSlot("U1", i + 3);
          this.spaceSlots = 3;
          size = 2
        } else if (code.substr(0, 2) === 'U5') {
          this.setSlot("U1", i);
          this.setSlot("U1", i + 1);
          this.setSlot("U1", i + 2);
          this.setSlot("U1", i + 3);
          this.setSlot("U1", i + 4);
          this.spaceSlots = 3;
          size = 2
        } else if (code.substr(0, 2) === 'I1') {
          this.setSlot("I1", i);
          size = 2
        } else if (code.substr(0, 2) === 'I2') {
          this.setSlot("I1", i);
          this.setSlot("I1", i + 1);
          this.spaceSlots = 1;
          size = 2
        } else if (code.substr(0, 2) === 'I3') {
          this.setSlot("I1", i);
          this.setSlot("I1", i + 1);
          this.setSlot("I1", i + 2);
          this.spaceSlots = 2;
          size = 2
        } else if (code.substr(0, 2) === 'I4') {
          this.setSlot("I1", i);
          this.setSlot("I1", i + 1);
          this.setSlot("I1", i + 2);
          this.setSlot("I1", i + 3);
          this.spaceSlots = 3;
          size = 2
        } else if (code.substr(0, 2) === 'I5') {
          this.setSlot("I1", i);
          this.setSlot("I1", i + 1);
          this.setSlot("I1", i + 2);
          this.setSlot("I1", i + 3);
          this.setSlot("I1", i + 4);
          this.spaceSlots = 3;
          size = 2
        } else if (code.substr(0, 4) === 'ETH1') {
          this.setSlot("ETH1", i);
          size = 4
        } else if (code.substr(0, 4) === 'ETH2') {
          this.setSlot("ETH2", i);
          size = 4
        } else if (code.substr(0, 3) === 'HD1') {
          this.setSlot("HD1", i);
          size = 3
        } else if (code.substr(0, 2) === 'AS') {
          this.setSlot("AS", i);
          size = 2
        } else if (code.substr(0, 3) === 'TV1') {
          this.setSlot("TV1", i);
          size = 3
        } else if (code.substr(0, 3) === 'TV2') {
          this.setSlot("TV2", i);
          size = 3
        }

          // else if(code.substr(0, 2) === 'B4'){
          //   this.setSlot("B4", i);
          //   size = 3
        // }

        else if (code.substr(0, 3) === 'FB1') {
          this.setSlot("FB1", i);
          size = 3
        } else if (code.substr(0, 3) === 'FB2') {
          this.setSlot("FB1", i);
          this.setSlot("FB1", i + 1);
          this.spaceSlots = 1;
          size = 3
        } else if (code.substr(0, 3) === 'FB3') {
          this.setSlot("FB1", i);
          this.setSlot("FB1", i + 1);
          this.setSlot("FB1", i + 2);
          this.spaceSlots = 2;
          size = 3
        } else if (code.substr(0, 3) === 'FB4') {
          this.setSlot("FB1", i);
          this.setSlot("FB1", i + 1);
          this.setSlot("FB1", i + 2);
          this.setSlot("FB1", i + 3);
          this.spaceSlots = 3;
          size = 3
        } else if (code.substr(0, 3) === 'FB5') {
          this.setSlot("FB1", i);
          this.setSlot("FB1", i + 1);
          this.setSlot("FB1", i + 2);
          this.setSlot("FB1", i + 3);
          this.setSlot("FB1", i + 4);
          this.spaceSlots = 3;
          size = 3
        }



        else if (code.substr(0, 3) === 'V02') {
          this.setSlot("V02", i);
          size = 3
        } else if (code.substr(0, 3) === 'V12') {
          this.setSlot("V12", i);
          size = 3
        } else if (code.substr(0, 3) === 'V22') {
          this.setSlot("V22", i);
          size = 3
        } else if (code.substr(0, 3) === 'H02') {
          this.setSlot("H02", i);
          size = 3
        } else if (code.substr(0, 3) === 'H12') {
          this.setSlot("H12", i);
          size = 3
        } else if (code.substr(0, 3) === 'H22') {
          this.setSlot("H22", i);
          size = 3
        } else if (code.substr(0, 3) === 'H08') {
          this.setSlot("H08", i);
          size = 2
        } else if (code.substr(0, 3) === 'H18') {
          this.setSlot("H18", i);
          size = 2
        } else if (code.substr(0, 3) === 'H28') {
          this.setSlot("H28", i);
          size = 2
        } else if (code.substr(0, 3) === 'V08') {
          this.setSlot("H08", i);
          size = 2
        } else if (code.substr(0, 3) === 'V18') {
          this.setSlot("H18", i);
          size = 2
        } else if (code.substr(0, 3) === 'V28') {
          this.setSlot("H28", i);
          size = 2
        } else if (code.substr(0, 4) === 'CHac') {
          this.setSlot("CHac", i);
          size = 4
        } else if (code.substr(0, 5) === 'RCHac') {
          this.setSlot("RCHac", i);
          size = 5
        }

        else if (code.substr(0, 2) === 'K1') {
          this.setSlot("K1", i);
          size = 2
        } else if (code.substr(0, 3) === 'VK2') {
          this.setSlot("VK2", i);
          size = 3
        } else if (code.substr(0, 3) === 'HK2') {
          this.setSlot("HK2", i);
          size = 3
        } else if (code.substr(0, 2) === 'K4') {
          this.setSlot("K4", i);
          if (this.isActiveSeries()){
            size = 3
          }else {
            size = 2
          }
        } else if (code.substr(0, 2) === 'K6') {
          this.setSlot("K6", i);
          size = 3
        } else if (code.substr(0, 3) === 'HK8') {
          this.setSlot("HK8", i);
          size = 2
        } else if (code.substr(0, 3) === 'VK8') {
          this.setSlot("HK8", i);
          size = 2
        }

        else if (code.substr(0, 1) === '8') {
          // Пропуск если выбран на предыдущем шаге 8 выключатель и оставили 8 специально
          size = 1
        }
        return code.substr(size)
      },
      setSlot(val, key) {
        if (this.series !== "90" && val === 'R1') {
          // this.slots[key] = 'S1';
          this.slots[key] = val;
        } else {
          this.slots[key] = val;
        }
      },
      getStylesSize(slot) {
        let width = this.sizeSlot + 'px';
        let height = this.sizeSlot + 'px';
        if (slot === 'HK8' || slot === 'H08' || slot === 'H18' || slot === 'H28') {
          if (this.orientation === 'Horizontal')
            width = this.sizeSlot * 2 + 'px';
          else
            height = this.sizeSlot * 2 + 'px';
        }
        return {width, height}
      },
      getURLImageRound() {
        if (!this.currentSocketName || this.currentSocketName === 'notFound') {
          return require(`../../assets/img/sockets/notFound-round.png`)
        } else {
          return require(`../../assets/img/sockets/${this.currentSocketName}-round.png`)
        }
        // return require(`../../assets/img/sockets/${this.currentSocketName}-round.png`)
      },
      getURLImageFranko() {
        if (!this.currentSocketName || this.currentSocketName === 'notFound') {
          return require(`../../assets/img/sockets/notFound-franko.png`)
        } else {
          return require(`../../assets/img/sockets/${this.currentSocketName}-franko.png`)
        }
        // return require(`../../assets/img/sockets/${this.currentSocketName}-franko.png`)
      },
      getURLImageBritish() {
        if (this.currentSocketName === 'notFound') {
          return require(`../../assets/img/sockets/notFound-british.png`)
        } else {
          return require(`../../assets/img/sockets/background-british.png`)
        }
      },
      getURLImageIndian() {
        if (this.currentSocketName === 'notFound') {
          return require(`../../assets/img/sockets/notFound-indian.png`)
        } else {
          return require(`../../assets/img/sockets/background-indian.png`)
        }
      },
      getURLImageEthernet() {
        if (this.currentSocketName === 'notFound') {
          return require(`../../assets/img/sockets/notFound-eth.png`)
        } else {
          return require(`../../assets/img/sockets/background-eth.png`)
        }
      },
      getURLImageEthernet2() {
        if (this.currentSocketName === 'notFound') {
          return require(`../../assets/img/sockets/notFound-eth2.png`)
        } else {
          return require(`../../assets/img/sockets/background-eth2.png`)
        }
      },
      getURLImageHD() {
        if (this.currentSocketName === 'notFound') {
          return require(`../../assets/img/sockets/notFound-hdmi.png`)
        } else {
          return require(`../../assets/img/sockets/background-hdmi.png`)
        }
      },
      getURLImageAS() {
        if (this.currentSocketName === 'notFound') {
          return require(`../../assets/img/sockets/notFound-as.png`)
        } else {
          return require(`../../assets/img/sockets/background-as.png`)
        }
      },
      getURLImageTV() {
        if (this.currentSocketName === 'notFound') {
          return require(`../../assets/img/sockets/notFound-tv.png`)
        } else {
          return require(`../../assets/img/sockets/background-tv.png`)
        }
      },
      getURLImageTV2() {
        if (this.currentSocketName === 'notFound') {
          return require(`../../assets/img/sockets/notFound-tv2.png`)
        } else {
          return require(`../../assets/img/sockets/background-tv2.png`)
        }
      },
      getURLImageSocketUSB() {
        if (!this.currentSocketName || this.currentSocketName === 'notFound') {
          return require(`../../assets/img/sockets/notFound-socket-usb.png`)
        } else {
          return require(`../../assets/img/sockets/${this.currentSocketName}-socket-usb.png`)
        }
        // return require(`../../assets/img/sockets/${this.currentSocketName}-socket-usb.png`)
      },
      getURLImageUSB() {
        return require(`../../assets/img/sockets/${this.currentSocketName !== "notFound" ? 'color' : this.currentSocketName}-usb-equal.png`)
      },
      getURLImageSK() {
        if (this.palette.length) {
          return require(`../../assets/img/sockets/socket.png`)
        } else {
          return require(`../../assets/img/combo-slots/socket.png`)
        }
      },
      getIconUrl(index, arrIndex) {
        if (this.slotsIcons && this.slotsIcons[arrIndex] && this.slotsIcons[arrIndex][index] === 'no_button') {
          return false
        }
        let imgName = undefined
        if (this.slotsIcons[arrIndex] && this.slotsIcons[arrIndex][index]) {
          imgName = `${this.slotsIcons[arrIndex][index]}-${this.colorIcon}.png`
          return require(`../../assets/img/new_icons/${imgName}`)
        }
        return imgName
      },
      woodCombo() {
        let url = ''
        if(this.series === 'W85'){
          this.woodItems.forEach(palette => {
            if (this.parameters && palette.value === this.parameters.wood) {
              if (this.orientation === 'Horizontal' && this.parameters.sensorsCount === '2') {
                url = palette.paletteImgH
              } else {
                url = palette.paletteImg;
              }
            }
          })
          return url
        }

      },
      isActiveSeries(){
        return this.series === 'A85' || this.series === 'A90'
      },
    }
  }
</script>

<style lang="scss">
  .ComboSmallPreview {
    display: inline-flex;
    margin: 10px;
    cursor: pointer;
    background-size: cover;

    &[data-orientation="vertical"] {
      flex-direction: column;

      .ComboSmallPreview__Item {
        border-right: 1px solid #929292;
        border-left: 1px solid #929292;
        border-bottom: 1px solid #929292;

        &:first-child {
          border-top: 1px solid #929292;
        }

        &-H08 {
          display: flex;
          padding: 5%;

          & > .help {
            height: 100%;
            width: 50%;
            position: relative;

            &:first-child {
              border-right: 1px solid #929292;

              &:after {
                content: "";
                z-index: 9;
                background-color: #fff;
                height: 1px;
                position: absolute;
                left: 0;
                top: 50%;
                right: -100%;
              }

              &:before {
                content: "";
                z-index: 9;
                background-color: #fff;
                height: 1px;
                position: absolute;
                left: 0;
                top: 23%;
                right: -100%;
              }
            }

            &:last-child {
              &:after {
                content: "";
                z-index: 9;
                background-color: #fff;
                height: 1px;
                position: absolute;
                left: -100%;
                top: 77%;
                right: 0;
              }
            }
          }

          .icon {
            width: 20%;

            &.icon-num-1 {
              top: 3%;
              left: 5%;
            }

            &.icon-num-2 {
              top: 3%;
              left: 53%;
            }

            &.icon-num-3 {
              top: 28%;
              left: 3%;
            }

            &.icon-num-4 {
              top: 28%;
              left: 53%;
            }

            &.icon-num-5 {
              top: 53%;
              left: 3%;
            }

            &.icon-num-6 {
              top: 53%;
              left: 53%;
            }

            &.icon-num-7 {
              top: 78%;
              left: 3%;
            }

            &.icon-num-8 {
              top: 78%;
              left: 53%;
            }
          }
        }
      }
    }

    &[data-orientation="horizontal"] {
      flex-direction: row;

      .ComboSmallPreview__Item {
        border-top: 1px solid #929292;
        border-bottom: 1px solid #929292;
        border-right: 1px solid #929292;

        &:first-child {
          border-left: 1px solid #929292;
        }

        &-H08 {
          display: flex;
          flex-direction: column;
          padding: 2.5%;

          & > .help {
            height: 50%;
            width: 100%;
            position: relative;

            &:first-child {
              border-bottom: 1px solid #929292;

              &:after {
                content: "";
                z-index: 9;
                background-color: #fff;
                width: 1px;
                position: absolute;
                top: 0;
                left: 50%;
                bottom: -100%;
              }

              &:before {
                content: "";
                z-index: 9;
                background-color: #fff;
                width: 1px;
                position: absolute;
                top: 0;
                left: 23%;
                bottom: -100%;
              }
            }

            &:last-child {
              &:after {
                content: "";
                z-index: 9;
                background-color: #fff;
                width: 1px;
                position: absolute;
                top: -100%;
                left: 77%;
                bottom: 0;
              }
            }
          }

          .icon {
            width: 10%;

            &.icon-num-1 {
              top: 5%;
              left: 3%;
            }

            &.icon-num-2 {
              top: 5%;
              left: 28%;
            }

            &.icon-num-3 {
              top: 5%;
              left: 53%;
            }

            &.icon-num-4 {
              top: 5%;
              left: 78%;
            }

            &.icon-num-5 {
              top: 55%;
              left: 3%;
            }

            &.icon-num-6 {
              top: 55%;
              left: 28%;
            }

            &.icon-num-7 {
              top: 55%;
              left: 53%;
            }

            &.icon-num-8 {
              top: 55%;
              left: 78%;
            }
          }
        }
      }
    }

    &__Item {
      display: flex;
      position: relative;

      &-Slot {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;

        .icon {
          width: 20%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;

          &:after {
            content: "";
            display: block;
            padding-top: 100%;
            width: 100%;
          }

          &:nth-child(1) {
            top: 5%;
            left: 5%;
          }

          &:nth-child(2) {
            top: 5%;
            left: 55%;
          }

          &:nth-child(3) {
            top: 55%;
            left: 5%;
          }

          &:nth-child(4) {
            top: 55%;
            left: 55%;
          }
        }
      }

      &-R1 {
        background: url("../../assets/img/sockets/notFound-round.png") center no-repeat;
        background-size: 100%;
      }

      &-RCHac {
        background: url("../../assets/img/sockets/notFound-socket-usb.png") center no-repeat;
        background-size: 100%;
      }

      &-CHac {
        background: url("../../assets/img/sockets/notFound-usb-equal.png") center no-repeat;
        background-size: 100%;
      }

      &-S1 {
        display: flex;
        align-items: center;
        justify-content: center;

        .shadow {
          position: relative;
          width: 70%;

          &.with-sh {
            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 6%;
              right: 6%;
              bottom: 6%;
              left: 6%;
              box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.75);
              border-radius: 12%;
              background-color: #333;
            }
          }
        }

        img {
          display: block;
          width: 100%;
        }
      }

      &-V02 {
        &:after {
          position: absolute;
          content: "";
          width: 1px;
          top: 5%;
          bottom: 5%;
          left: calc(50% - 1px);
          background-color: #929292;
        }
      }

      &-H02 {
        &:after {
          position: absolute;
          content: "";
          height: 1px;
          left: 5%;
          right: 5%;
          top: calc(50% - 1px);
          background-color: #929292;
        }

        .icon {
          &:nth-child(2) {
            top: 55%;
            left: 5%;
          }
        }
      }

      &-04 {
        .icon {
          &.icon-num-1 {
            top: 5%;
            left: 5%;
          }

          &.icon-num-2 {
            top: 5%;
            left: 55%;
          }

          &.icon-num-3 {
            top: 55%;
            left: 5%;
          }

          &.icon-num-4 {
            top: 55%;
            left: 55%;
          }
        }

        &:after {
          position: absolute;
          content: "";
          height: 1px;
          left: 5%;
          right: 5%;
          top: calc(50% - 1px);
          background-color: #929292;
        }

        &:before {
          position: absolute;
          content: "";
          width: 1px;
          top: 5%;
          bottom: 5%;
          left: calc(50% - 1px);
          background-color: #929292;
        }


      }

      &[data-inlay="BR"] {
        .ComboSmallPreview__Item-Slot > .help {
          border-color: #FEDC6F !important;

          &:after {
            background-color: #FEDC6F !important;
          }

          &:before {
            background-color: #FEDC6F !important;
          }
        }

        .ComboSmallPreview__Item-Slot:after, .ComboSmallPreview__Item-Slot:before {
          background-color: #FEDC6F;
        }
      }
    }
  }
</style>
