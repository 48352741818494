<template>
  <div class="select-icon" :class="{'opened': isVisible}">
    <div class="select-icon__list">
      <div class="select-icon__close" @click="closeModal">&#10006;</div>
      <div class="select-icon__item" v-for="(icon, i) in icons" @click="selectIcon(icon)" :key="i">
        <img :src="require(`../../assets/img/new_icons/${icon.iconName}-b.png`)" :alt="i+1">
      </div>
      <p
              v-if="shouldNoButton()"
              @click="selectIcon({ iconName: 'no_button' })" style="width: 100%;">No button</p>
      <p @click="selectIcon({ iconName: 'empty' })" style="width: 100%;">No icon</p>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapMutations} from "vuex";

export default {
  name: "ModalSelectIcon",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    currentTouch: {
      type: Number,
      default: -1
    },
    destination: {
      type: String,
      default: 'switch'
    }
  },
  data() {
    return {
      icons: [
        { iconName: '1' },
        { iconName: '2' },
        { iconName: '3' },
        { iconName: '4' },
        { iconName: '5' },
        { iconName: '6' },
        { iconName: '7' },
        { iconName: '8' },
        { iconName: '9' },
        { iconName: '10' },
        { iconName: '11' },
        { iconName: '12' },
        { iconName: '13' },
        { iconName: '14' },
        { iconName: '15' },
        { iconName: '16' },
        { iconName: '17' },
        { iconName: '18' },
        { iconName: '19' },
        { iconName: '20' },
        { iconName: '21' },
        { iconName: '22' },
        { iconName: '23' },
        { iconName: '24' },
        { iconName: '25' },
        { iconName: '26' },
        { iconName: '27' },
        { iconName: '28' },
        { iconName: '29' },
        { iconName: '30' },
        { iconName: '31' },
        { iconName: '32' },
        { iconName: '33' },
        { iconName: '34' },
        { iconName: '35' },
        { iconName: '36' },
        { iconName: '37' },
        { iconName: '38' },
        { iconName: '39' },
        { iconName: '40' },
        { iconName: '41' },
        { iconName: '42' },
        { iconName: '43' },
        { iconName: '44' },
        { iconName: '45' },
        { iconName: '46' },
        { iconName: '47' },
        { iconName: '48' },
        { iconName: '49' },
        { iconName: '50' },
        { iconName: '51' },
        { iconName: '52' },
        { iconName: '53' },
        { iconName: '54' },
        { iconName: '55' },
        { iconName: '56' },
        { iconName: '57' },
        { iconName: '58' },
        { iconName: '59' },
        { iconName: '60' },
        { iconName: '61' },
        { iconName: '62' },
        { iconName: '63' },
        { iconName: '64' },
        { iconName: '65' },
        { iconName: '66' },
        { iconName: '67' },
        { iconName: '68' },
        { iconName: '69' },
        { iconName: '70' },
        { iconName: '71' },
        { iconName: '72' },
        { iconName: '73' },
        { iconName: '74' },
        { iconName: '75' },
        { iconName: '76' },
        { iconName: '77' },
        { iconName: '78' },
        { iconName: '79' },
        { iconName: '80' },
        { iconName: '81' },
        { iconName: '82' },
        { iconName: '83' },
        { iconName: '84' },
        { iconName: '85' },
        { iconName: '86' },
        { iconName: '87' },
        { iconName: '88' },
        { iconName: '89' },
        { iconName: '90' },
        { iconName: '91' },
        { iconName: '92' },
        { iconName: '93' },
        { iconName: '94' },
        { iconName: '95' },
        { iconName: '96' },
        { iconName: '97' },
        { iconName: '98' },
        { iconName: '99' },
        { iconName: '100' },
        { iconName: '101' },
        { iconName: '102' },
        { iconName: '103' },
        { iconName: '104' },
        { iconName: '105' },
        { iconName: '106' },
        { iconName: '107' },
        { iconName: '108' },
        // { iconName: '109' },
        { iconName: '119' },
        { iconName: '145' },
        { iconName: '111' },
        { iconName: '112' },
        // { iconName: '113' },
        // { iconName: '114' },
        // { iconName: '115' },
        // { iconName: '116' },
        { iconName: '117' },
        { iconName: '118' },
        { iconName: '120' },
        { iconName: '121' },
        { iconName: '122' },
        { iconName: '123' },
        { iconName: '124' },
        { iconName: '125' },
        { iconName: '126' },
        { iconName: '127' },
        { iconName: '128' },
        { iconName: '129' },
        { iconName: '130' },
        { iconName: '131' },
        { iconName: '132' },
        { iconName: '133' },
        // { iconName: '134' },
        // { iconName: '135' },
        { iconName: '136' },
        { iconName: '137' },
        { iconName: '138' },
        { iconName: '139' },
        // { iconName: '140' },
        { iconName: '110' },
        { iconName: '141' },
        { iconName: '142' },
        { iconName: '143' },
        { iconName: '144' },
        { iconName: '146' },
        { iconName: '147' },
        { iconName: '148' },
        // { iconName: '149' },
        { iconName: '150' },
        { iconName: '151' },
        // { iconName: '152' },
        // { iconName: '153' },
        { iconName: '154' },
        // { iconName: '155' },
        // { iconName: '156' },
        { iconName: '157' },
        { iconName: '158' },
        { iconName: '159' },
        { iconName: '160' },
      ]
    }
  },
  computed: {
    ...mapGetters({
      tab: "Parameters/tab",
      touchParameters: 'Parameters/touchParameters',
      comboParameters: 'Parameters/comboParameters',
    }),
  },
  methods: {
    ...mapMutations({
      UPDATE_ICONS: "Parameters/UPDATE_ICONS"
    }),
    closeModal(){
      this.$emit('close')
    },
    selectIcon(icon){
      if (icon.iconName === 'no_button'){
        this.touchParameters.buttonsCount = this.touchParameters.buttonsCount - 1
        this.comboParameters.noButtonsCount = this.comboParameters.noButtonsCount + 1
      }
      if(this.destination === 'switch'){
        let data = {
          index: this.currentTouch,
          value: icon.iconName
        }
        this.UPDATE_ICONS(data);
        this.$emit('close');
      } else if (this.destination === 'combo'){
        this.$emit('select-icon', icon)
      }
    },
    shouldNoButton(){
      if(this.tab === 'switch'){
        if(this.touchParameters.series === 'A85' || this.touchParameters.series === 'A90'){
          return true
        }
      }else if (this.tab === 'combo'){
        if(this.comboParameters.series === 'A85' || this.comboParameters.series === 'A90'){
          return true
        }
      }else {
        return false
      }
    },
    trigerArtikul(){
      this.touchParameters.isButton = 1
    }
  }
}
</script>

<style lang="scss">
  .select-icon.opened{
    display: flex;
  }
</style>
