import mainApi from "../../api/main-api";

export default {
  LINKING_SMART_CATALOG({state, rootState}, params){

    mainApi.linkingSmartCatalog(params).then(response => {
      if (!response.error) {
        let params = response
        let token = state.user.token
        mainApi.saveTokenSmartCatalog(params, token).then(response_2 => {
          // localStorage.setItem('token_smart_catalog', JSON.stringify(response_2));
          state.user.token_smart_catalog = response_2
          rootState.Cabinet.showModalSaveCollection = true
          rootState.Cabinet.showModalAuthenticationSmartCatalog = false
        });
      } else {
        state.messageAuthenticationSmartCatalog = response.error
      }
    });
  },
}
