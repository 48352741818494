export default () => ({
  tab: 'switch',
  touchParameters: {
    series: '85',
    interface: null,
    inlay: null,
    sensorsCount: 4,
    buttons: 4,
    buttonsCount: 4,
    orientation: null,
    surface: null,
    switchesCount: 1,
    palette: null,
    wood: null,
    material: 'stone',
    img: '',
  },
  comboParameters: {
    series: '85',
    slotsCount: 2,
    orientation: "Horizontal",
    material: 'stone',
    surface: null,
    comboCount: 1,
    palette: null,
    wood: null,
    // switch params
    switchSensorsCount: null,
    switchOrientation: null,
    switchInlay: null,
    switchInterface: "DRY CONTACT",
    // socket params
    colorSocket: 'NF',
    selectedSlots: {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null
    },
    slotsTouchIcons: {
      1: [],
      2: [],
      3: [],
      4: [],
      5: []
    },
    noButtonsCount: 0,
  },
  selectedSwitchPalette: {},
  selectedComboPalette: {},
  selectedSwitchWood: {},
  selectedComboWood: {},
  icons: [],
  updateItem: {
    index: -1,
    name: ''
  },
  img: '',
})
