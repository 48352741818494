<template>
  <div>
    <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :filename="orderName.length ? orderName : 'order'"
            :pdf-quality="2"
            :paginate-elements-by-height="1000"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="780px"
            ref="html2Pdf"
    >
      <PDFContent slot="pdf-content" :orderName="orderName"></PDFContent>
    </vue-html2pdf>
    <div id="Content" class="container">
      <Nav :selected-tab="tab" @change="setTab"/>
      <SwitchSettings v-if="tab === 'switch'" @save-order-to-pdf="saveAsPdf"/>
      <ComboSettings v-if="tab === 'combo'" @save-order-to-pdf="saveAsPdf"/>
      <SocketsFramesSettings v-if="tab === 'sockets'" @save-order-to-pdf="saveAsPdf"/>


<!--      <p style="color: white">{{collection}}</p>-->
<!--      <p style="color: white">{{user}}</p>-->

      <Collection @change-tab="changeTab"></Collection>
      <div class="text-center" v-if="collection.length">
        <button type="button" class="form-create-touch__button-save" @click="resetCollection">Reset Table</button>
        <button type="button" class="form-create-touch__button-save" @click="openModalSaveCollection">Save as PDF
        </button>
      </div>
    </div>
    <ModalAuthentication @close="closeModalAuthentication" :isVisible="showModalAuthentication"/>
    <AuthenticationSmartCatalog
            @close="closeModalAuthenticationSmartCatalog"
            :isVisible="showModalAuthenticationSmartCatalog"
    />
    <ModalSaveCollection
            @saveAsPdf="saveAsPdf"
            @saveCollection="saveCollection"
            @resaveCollection="resaveCollection"
            @close="closeModalSaveCollection"
            @openModalAuthenticationSmartCatalog="openModalAuthenticationSmartCatalog"
            :isVisible="showModalSaveCollection"
    />
  </div>
</template>

<script>
  import PDFContent from '../components/Collection/PDFContent'
  import Nav from '../components/Nav/Nav'
  import SwitchSettings from '../views/SwitchSettings'
  import ComboSettings from '../views/ComboSettings'
  import SocketsFramesSettings from '../views/SocketsFramesSettings'
  import Collection from '../components/Collection'
  import ModalAuthentication from '../components/Modals/Authentication'
  import AuthenticationSmartCatalog from '../components/Modals/AuthenticationSmartCatalog'
  import ModalSaveCollection from '../components/Modals/SaveCollection'
  import VueHtml2pdf from 'vue-html2pdf'
  import {mapActions, mapGetters, mapMutations} from "vuex";
  import router from '../router';
  import axios from "axios";

  export default {
    name: 'Home',
    components: {
      PDFContent,
      Nav,
      SwitchSettings,
      ComboSettings,
      SocketsFramesSettings,
      Collection,
      ModalAuthentication,
      AuthenticationSmartCatalog,
      ModalSaveCollection,
      VueHtml2pdf
    },
    data() {
      return {
        orderName: "",
        //tab: ''
      }
    },
    computed: {
      ...mapGetters({
        tab: "Parameters/tab",
        collection: "Collection/collection",
        user: "Cabinet/user",
        collection_name: "Collection/collection_name",
        showModalAuthentication: "Cabinet/showModalAuthentication",
        showModalAuthenticationSmartCatalog: "Cabinet/showModalAuthenticationSmartCatalog",
        showModalSaveCollection: "Cabinet/showModalSaveCollection",
      }),
    },
    methods: {
      ...mapActions({
        GET_COLLECTION: 'Collection/getCollection',
      }),
      ...mapMutations({
        SET_TAB: "Parameters/SET_TAB",
        SET_UPDATE_ITEM: "Parameters/SET_UPDATE_ITEM",
        RESET_COLLECTION: "Collection/RESET_COLLECTION",
        MODAL_AUTHENTICATION: "Cabinet/MODAL_AUTHENTICATION",
        MODAL_AUTHENTICATION_SMART_CATALOG: "Cabinet/MODAL_AUTHENTICATION_SMART_CATALOG",
        MODAL_SAVE_COLLECTION: "Cabinet/MODAL_SAVE_COLLECTION",
        // GET_USER: "Cabinet/GET_USER",
        CLEAR_MESSAGE: "Collection/CLEAR_MESSAGE",
        SAVE_COLLECTION_NAME: "Collection/SAVE_COLLECTION_NAME",
      }),
      openModalSaveCollection() {
        if (!this.user.login) {
          this.MODAL_AUTHENTICATION(true)
          // return false;
        } else {
          this.MODAL_SAVE_COLLECTION(true)
        }
      },
      resaveCollection(params) {
        this.orderName = this.collection_name
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        return axios.post('https://ns-touch.com/wp-json/api/resave-collection', {
          headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
          },
          params
        }).then((response) => {
          this.SAVE_COLLECTION_NAME(response.data)
          setTimeout(() => {
            window.scrollTo(0, 0)
            this.$refs.html2Pdf.downloadPdf()
          }, 500)
        })
      },
      saveCollection(params) {
        this.orderName = this.collection_name
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        return axios.post('https://ns-touch.com/wp-json/api/save-collection', {
          headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
          },
          params
        }).then((response) => {
          this.SAVE_COLLECTION_NAME(response.data)
          setTimeout(() => {
            window.scrollTo(0, 0)
            this.$refs.html2Pdf.downloadPdf()
          }, 500)
        })
      },
      saveAsPdf() {
        // console.log(this.$refs.innerHTML)
        // console.log(this.$el.getElementsByClassName("vue-html2pdf")[0].innerHTML);
        // debugger
        this.orderName = this.collection_name
        setTimeout(() => {
          window.scrollTo(0, 0)
          // this.$refs.html2Pdf.downloadPdf()
        }, 500)

        // this.$swal({
        //   title: 'Name of document',
        //   input: 'text',
        //   inputAttributes: {
        //     autocapitalize: 'off'
        //   },
        //   showCancelButton: true,
        //   confirmButtonText: 'Create PDF',
        //   cancelButtonText: 'Cancel',
        //   showLoaderOnConfirm: true,
        // }).then((result) => {
        //   if(result.isConfirmed){
        //     this.orderName = result.value
        //     setTimeout(() => {
        //       window.scrollTo(0, 0)
        //       this.$refs.html2Pdf.generatePdf()
        //     }, 0)
        //   }
        // })
      },
      setTab(tabName) {
        this.SET_TAB(tabName)
        // this.tab = tabName;
        let updateItem = {
          index: -1,
          name: ''
        }
        this.SET_UPDATE_ITEM(updateItem)
      },
      changeTab(val) {
        if (val !== 'switch') {
          this.tab = val
        } else {
          this.tab = ''
        }
      },
      resetCollection() {
        router.push("/")
        this.RESET_COLLECTION()
      },
      closeModalAuthentication() {
        this.MODAL_AUTHENTICATION(false)
      },
      closeModalAuthenticationSmartCatalog() {
        this.MODAL_AUTHENTICATION_SMART_CATALOG(false)
      },
      openModalAuthenticationSmartCatalog() {
        console.log('openModalAuthenticationSmartCatalog')
        this.MODAL_AUTHENTICATION_SMART_CATALOG(true)
      },
      closeModalSaveCollection() {
        this.CLEAR_MESSAGE()
        this.MODAL_SAVE_COLLECTION(false)
      },
    },
    beforeMount() {
      // const encryptedText = this.$CryptoJS.AES.encrypt("Hi There!", "Secret Passphrase").toString()
      // const encryptedText = this.$CryptoJS.MD5("Hi There!").toString()
      // let hash = CryptoJS.MD5("Message")
      // console.log(encryptedText)

      let params = (new URL(document.location)).searchParams;
      if (params.get("collection_id")) {
        this.GET_COLLECTION({
          collection_id: params.get("collection_id"),
          history_id: params.get("history_id"),
        })
      }
      // this.GET_USER()
    },
    // updated() {
    //     let params = (new URL(document.location)).searchParams;
    //     if(params.get("collection_id")){
    //         this.GET_COLLECTION({collection_id: params.get("collection_id")})
    //     }
    // }
  }
</script>

<style lang="scss">
  #Content {
    padding-bottom: 30px;
  }

  .text-center {
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
  }

  .half-block-flex__child {
    min-height: 100%;
    position: relative;
    z-index: 99;

    .half-block-flex__sticky {
      position: sticky;
      top: 0;
    }
  }

  .form-create-touch__button-save {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media all and (max-width: 767px) {
    #Content {
      padding-top: 75px;
    }

    .touch-container {
      & > .flex {
        display: block;
      }

      .half-block-flex:nth-child(1), .half-block-flex:nth-child(2) {
        max-width: 100%;
      }
    }
  }
</style>
