<template>
    <div class="flex justify-between align-start wrap color-touch__container">
        <label class="color-touch" v-for="palette in paletteItems" :key="palette.id">
            <input
                    type="radio"
                    name="touch-color-wood"
                    v-model="touchParameters.wood"
                    class="touch-color qqqqqq"
                    :value="palette.value"
                    @change="selectSwitchWood(palette)"
                    v-if="forTo === 'switch'"
            >
            <input
                    type="radio"
                    name="combo-color-wood"
                    v-model="comboParameters.wood"
                    class="touch-color dfgdfg"
                    :value="palette.value"
                    @change="selectComboWood(palette)"
                    v-if="forTo === 'combo'"
            >
            <div
                    class="form-create-touch__color text-primary wood"
                    :style="{backgroundImage: palette.paletteImg}"></div>
            <span>{{palette.value}}</span>
        </label>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'
    import {CONSTANTS} from "@/utilites/utilites"
    export default {
        name: "Wood",
        data(){
            return {
                paletteItems: CONSTANTS['woodItems'],
            }
        },
        props: {
            forTo: {
                type: String,
                default: 'switch'
            }
        },
        computed: {
            ...mapGetters({
                touchParameters: 'Parameters/touchParameters',
                comboParameters: 'Parameters/comboParameters',
            }),
        },
        methods: {
            ...mapMutations({
                SET_SELECTED_SWITCH_WOOD: 'Parameters/SET_SELECTED_SWITCH_WOOD',
                SET_SELECTED_COMBO_WOOD: 'Parameters/SET_SELECTED_COMBO_WOOD',
            }),
            selectSwitchWood(palette){
                this.SET_SELECTED_SWITCH_WOOD(palette)
            },
            selectComboWood(palette){
                this.SET_SELECTED_COMBO_WOOD(palette)
            }
        }
    }
</script>